import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EstatePlanningTraverseCity = () => (
  <Layout>
    <SEO title="Estate Planning in Traverse City &amp; Northern Michigan" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">
              Estate Planning in Traverse City &amp; Northern Michigan
            </h1>
            <h2 className="subtitle is-size-3">
              At Rysso Law, PLLC we have years of experience with Powers of
              Attorney, Probate, Wills &amp; Deeds, as well as trusts.
            </h2>
            <p>
              We can help you navigate the complicated landscape of estate
              planning. Our attorneys have the experience necessary to guide you
              through the planning process, leaving you confident and secure for
              the future.
            </p>
            <p>
              For more information please review our{" "}
              <Link to="/estate-planning-probate-faqs/">
                Estate Planning &amp; Probate FAQS
              </Link>{" "}
              page.
            </p>
            <p>
              If you have immediate questions or concerns, don't hesitate to
              call or text <a href="tel:231-649-2903">231-649-2903</a>, or visit
              our contact page to <Link to="/contact-us/">send an email</Link>.
            </p>
            <p>
              You may also be interested in reading our{" "}
              <Link to="/what-is-elder-law-medicaid-planning">
                Elder Law &amp; Medicaid Planning
              </Link>{" "}
              page.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default EstatePlanningTraverseCity
